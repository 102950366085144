import React from 'react';
import queryString from 'query-string';
import Layout from '../components/layout';
import Login from '../components/auth/login';
import { pageview } from '../utils/scripts';
import SEO from '../components/seo';

class SigninVerifyPage extends React.Component {
  componentDidMount() {
    pageview();
  }

  render() {

    const isBrowser = typeof window !== `undefined`;
    const parsed = isBrowser && window && window.location && window.location.search ? queryString.parse(window.location.search) : {};
    const email = parsed && parsed.email ? parsed.email : '';

    return (
      <Layout>
        <SEO title="Signin - Verify" />
        <section className="">
          <div className="container px-5 py-12 mx-auto flex flex-wrap items-center">
            <div className="lg:w-1/2 md:w-1/2 flex flex-col md:mx-auto w-full f">
              <div className="sm:mx-auto sm:w-full sm:max-w-md mb-8">
                <h2 className="mt-6 text-center text-2xl leading-9 font-extrabold text-gray-900 font-headline">
                  Thanks for validating your account!  We have just sent a one-time code to your email on file:<br />{email}
                </h2>
                <h3 className="mt-6 text-center text-lg leading-9 font-bold text-gray-900 font-headline">
                  If you no longer have access to this email account, please email <a href="mailto:andy@sidewallpizza.com">andy@sidewallpizza.com</a>.
                </h3>
              </div>
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 border border-gray-100">
                <Login />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default SigninVerifyPage;
