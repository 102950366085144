import React from 'react';
import * as yup from 'yup';
import queryString from 'query-string';
import { Link, navigate } from 'gatsby';
import Form from '../form/form';
import { apiUrl, postRequest } from '../../utils/request';
import { setJwt, setUser, isLoggedIn, logout } from '../../utils/auth';

const loginForm = {
  order: [
    'code',
    'submit',
  ],
  fields: {
    code: {
      schema: yup.string().required().trim(),
      label: "Please enter the code we just sent to you",
      type: "text",
      // placeholder: "e.g. steve@aol.com",
      // helptext: "We promise we won't spam you.",
    },
    submit: {
      type: 'button',
      submit: true,
      text: "Continue",
      color: "blue",
      block: true,
    },
  },
};



class Login extends React.Component {

  // Ensure we aren't logged in
  componentDidMount() {
    if (isLoggedIn()) {
      logout();
    }
  }

  submitForm = async (data, setErrors) => {
    const url = `${apiUrl}/auth/login`;
    const meUrl = `${apiUrl}/auth/me`;
    try {
      const result = await postRequest(url, data);
      setJwt(result.access_token);
      const user = await postRequest(meUrl, {});
      setUser(user);
      const parsed = window.location.search ? queryString.parse(window.location.search) : {};
      const redir = parsed && parsed.redir ? parsed.redir : '/app/dashboard';
      navigate(redir);
    } catch (err) {
      setErrors({
        code: 'The code was invalid, please try again.',
      });
    }
  }

  render() {
    return (
      <Form defaults={{}} submitForm={this.submitForm} form={loginForm} footer={<span>Code not working? <Link to="/signin" className={`font-bold`}>Request a new one &raquo;</Link></span>} />
    );
  }
}

export default Login;
